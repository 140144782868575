import React, { lazy, useEffect, useState } from 'react';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
// import {useDispatch, useSelector, useStore} from 'react-redux'
import {Sso} from 'container/Login/CommonCalls';
// import {storeGlobals} from 'actions';
import {referrer,API_SECRET_KEY } from 'strings';
import PrivateRoute from 'components/CustomComponents/PrivateRoute';

const LoginContainer = lazy(() => import('container/Login'));
const ForgotPasswordContainer = lazy(() => import('container/ForgotPassword'));
const SubmittedProductionsContainer = lazy(() => import('container/SubmittedProductions'));
const ProductionsDetailedViewContainer = lazy(() => import('container/ProductionsDetailedView'));
const PageNotFoundComponent = lazy(() => import('components/PageNotFound'));
const MyAccountContainer = lazy(() => import('container/MyAccount'));
const UsersContainer = lazy(() => import('container/AllUsers'));
const FaqsContainer = lazy(() => import('container/Faqs'));
const MessageCenter = lazy(() => import('container/MessageCenter'));
const PrivacyPolicyComponent = lazy(() => import('components/PrivacyPolicy'));

type Props = {
};

let RouteGuard: React.FC<Props> = (props) => {
    // let dispatch = useDispatch();
    // let store = useStore();
    // let userInfo = store.getState()?.globalState?.globals;
    let a = document.cookie?.split(';')?.filter(x => x.includes('producerId'))[0]?.split('=')[1];
    let b = document.cookie.split(';').filter(x => x.includes('nominate'))[0]?.split('=')[1];
    useEffect(() => {
        // userInfo ?
        // console.log(userInfo) :
        !a &&
        Sso()
        .then(x => {
            console.log(x);
            // (x?.http_code===200) &&
            // (
            //     dispatch(storeGlobals(x.payload))
            // )
            if (x?.http_code===200 && document.referrer!=='') {

            }
        })
        .catch(err => {
            console.log(err);
        })
    });

    const location = useLocation();
    useEffect(() => {
        console.log(location);
        setTimeout(function() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }, 100);
    }, [location.pathname]);

    return (
        <Switch>
            <Redirect exact from='/' to='/login'/>
            <Route exact path="/login" render={(props)=>(<LoginContainer isLogin={false} {...props}/>)}/>
            <Route exact path="/forgot-password" render={(props)=>(<ForgotPasswordContainer isForgot={true}/>)}/>
            <Route exact path="/create-password" render={(props)=>(<ForgotPasswordContainer isForgot={false}/>)}/>
            <Route exact path="/create-account" render={(props)=>(<LoginContainer isLogin={true} {...props}/>)}/>
            <PrivateRoute exact path="/my-productions" component={SubmittedProductionsContainer}/>
            <PrivateRoute exact path="/productions/detailed-view/:id" component={ProductionsDetailedViewContainer}/>
            <PrivateRoute exact path="/my-account" render={(props)=>(<MyAccountContainer edit={false} {...props}/>)}/>
            <PrivateRoute exact path="/my-account-edit" render={(props)=>(<MyAccountContainer edit={true} {...props}/>)}/>
            <PrivateRoute exact path="/all-users" component={UsersContainer}/>
            <PrivateRoute exact path="/faqs" component={FaqsContainer}/>
            <PrivateRoute exact path="/message-center" component={MessageCenter}/>
            <PrivateRoute path="/privacy-policy" component={PrivacyPolicyComponent}/>
            <Route path="/404" component={PageNotFoundComponent}/>
            <Redirect to='/404' />
        </Switch>
    )
}

export default RouteGuard;
