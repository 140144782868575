import React from 'react';
import {Footer, FooterMainSection, AddressSection,
    LinksSection, PartnersDropdownSection,
    SocialMediaLinksSection, SagLogo
} from './skins';
import '../../../src/index.css';
import DropDownField from 'components/InputComponents/DropDownField';
import HelpCenter from './HelpCenter';
import {partners} from 'components/DummyData';
import {ReactComponent as PhoneSvg} from 'assets/svg/footerSvg/phone-footer.svg';
import emailSvg from 'assets/svg/footerSvg/mail-footer.svg';
import facebookSvg from 'assets/svg/footerSvg/facebook-footer.svg';
import twitterSvg from 'assets/svg/footerSvg/twitter-footer.svg';
import instaSvg from 'assets/svg/footerSvg/insta-footer.svg';
import youtubeSvg from 'assets/svg/footerSvg/youtube-footer.svg';
import sagFooterLogo from 'assets/svg/footer-logo.svg';

const selectFieldStyles = {
    container: styles => ({...styles, backgroundColor: 'black',color: 'white'}), 
    control: styles => ({...styles, backgroundColor: 'black',height: '3.5em', width: 250, padding: '0px 1em', color: 'white', borderRadius:'0px'}),
    valueContainer: styles => ({...styles, fontSize: '0.8em'}),
    option: styles => ({...styles, color: 'white', padding: '18px 30px','&:hover':{background: '#3c3c3c', cursor: 'pointer', color: '#afafaf'}}),
    indicatorSeparator: styles => ({...styles, display: 'none'}),
    input: styles => ({...styles, color: 'white', fontSize:'0.8em'}),
    placeholder: styles => ({...styles, color: 'white', fontWeight: 600, fontSize:'1em'}), 
    singleValue: styles => ({...styles, color: 'white', fontWeight: 600, fontSize:'1em'}),
    menu: styles => ({...styles, color: 'white', width: 250, fontSize: '0.8em', backgroundColor:'black',  margin: 0, borderRadius: 0, footerIcons: 600}),
}

const FooterComponent = () => {
    return (
        <Footer className='mainFooter'>
            <h3>
                Screen Actors Guild - American Federation <br/>
                of Television and Radio Artists
            </h3>
            <FooterMainSection className='footerMainSection'>
                <AddressSection className='addressSection'>
                    <div>
                        5757 Wilshire Boulevard, 7th Floor
                    </div>
                    <div>
                        Los Angeles, California 90036
                    </div>
                    <div className="phone-contact">
                        {/* <img src={phoneSvg} alt={""}/> */}
                        <PhoneSvg alt={'Phone Icon'}/>
                        <a href={"tel:(855) 724-2387"}>
                            (855) SAG-AFTRA / (855) 724-2387
                        </a>
                    </div>
                    <div className="email-contact">
                        <img src={emailSvg} alt={""}/>
                        <a href={"mailto:info@sagaftra.org"}>
                            info@sagaftra.org
                        </a>
                    </div>
                    <SocialMediaLinksSection>
                        <div className="follow-us">
                            FOLLOW US
                        </div>
                        <div className="social-links">
                            <a href="//www.facebook.com/SAGAFTRA" target="_blank">
                                <img src={facebookSvg} alt={""}/>
                            </a>
                            <a href="//twitter.com/sagaftra" target="_blank">
                                <img src={twitterSvg} alt={""}/>
                            </a>
                            <a href="//www.instagram.com/sagaftra" target="_blank">
                                <img src={instaSvg} alt={""}/>
                            </a>
                            <a href="//www.youtube.com/user/SAGAFTRA" target="_blank">
                                <img src={youtubeSvg} alt={""}/>
                            </a>
                        </div>
                    </SocialMediaLinksSection>
                </AddressSection>
                <LinksSection className='linkSection'>
                    <ul>
                        <li>
                            <a href="https://www.sagaftra.org/contact-us" target="_blank">
                                CONTACT US
                            </a>
                        </li>
                        <li>
                            <a href="https://producerportal.sagaftra.org/faq" target="_blank">
                                FAQ
                            </a>
                        </li>
                        <li>
                            <a href="https://www.sagaftra.org/about/careers" target="_blank">
                                CAREERS AT SAG-AFTRA
                            </a>
                        </li>
                        <li>
                            <a href="https://www.sagaftra.org/accessibility-statement" target="_blank">
                                ACCESSIBILITY STATEMENT
                            </a>
                        </li>
                        <li>
                            <a href="https://www.sagaftra.org/parental-consent-needed-updated-coppa-policy" target="_blank">
                                COPPA POLICY
                            </a>
                        </li>
                        <li>
                            <a href="https://www.sagaftra.org/copyright" target="_blank">
                                COPYRIGHT
                            </a>
                        </li>
                        <li>
                            <a href="https://www.sagaftra.org/privacy-policy-terms-use" target="_blank">
                                PRIVACY POLICY/TERMS OF USE
                            </a>
                        </li>
                    </ul>
                    <div className="copyright" target="_blank">
                        <p>© 2020 SAG-AFTRA. All Rights Reserved</p>
                    </div>
                </LinksSection>
                <PartnersDropdownSection>
                    <DropDownField
                        styles={selectFieldStyles}
                        isSearchable={false}
                        onChange = {(val)=> window.open(val.url)}
                        placeholder={'PARTNERS'}
                        options={partners}
                        theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary: 'black',
                                primary50: 'black',
                                primary25: 'black'
                            },
                        })}
                        blackCaretIcon
                    />
                </PartnersDropdownSection>
            </FooterMainSection>
            <SagLogo className='globalFooter'>
                <img src={sagFooterLogo} alt={""}/>
                {/* <SagFooterLogo/> */}
            </SagLogo>
            <HelpCenter/>
        </Footer>
    );
}

export default FooterComponent;
