import styled from 'styled-components';
import { DisplayFlex } from 'skins';

const PpHeader = styled.header`
    ${DisplayFlex};
    `;

const YellowBar = styled.div`
    background-color: var(--yellow);
    height: 0.5em;
    flex: 0 0 25%;
    `;

const SiteNavigationSection = styled.section`
    ${DisplayFlex};
    flex-wrap: wrap;
    align-content: space-between;
    height: 10em;
    width: 100%;
    `;

const NavBar = styled.nav`
    flex: 1 0 100%;
    ${DisplayFlex};

    ul {
        list-style-type: none;
        padding-left: 0;
        ${DisplayFlex};
        flex: 0 0 80%;
        gap: 110px;

        li:not(:first-child) {
            align-self: flex-end;
        }
    }
    `;

const LoginSection = styled.section`
    ${DisplayFlex};
    height: 4em;
    width: 35em;

    > button,div , div > button {
        flex: 0 1 100%;
    }
    div{
        display: flex;
        position: relative;
        &>button{
            display: flex;
            align-items: center;
            justify-content: center;
            > svg{
                margin-right: 10px;
            }
        }
    }
    .loginBtn:after{
        align-self: center;
        display: inline-block;
        vertical-align: middle;
        content: "";
        width: 0;
        height: 0;
        pointer-events: none;
        border-top: 0.25rem solid;
        border-right: 0.25rem solid transparent;
        border-bottom: 0.25rem solid transparent;
        border-left: 0.25rem solid transparent;
        top: 1px;
        position: relative;
        left: 6px;
    }
    button{
        background-color: black;
        color: white;
        border: black;
        cursor: pointer;
        height: 4em;
    }
    section{
        position: absolute;
        width: 100%;
        top: 65px;
        display: flex;
        flex-direction: column;
        button{
            border-top: 1px solid;
            text-transform: uppercase;
        }
    }
    `;

export { PpHeader, YellowBar, SiteNavigationSection,
        NavBar, LoginSection };
<script type="text/javascript" src="https://sc23269568hms1.cobrowse.oraclecloud.com/launcher.js"></script>
