import React, { useEffect, useState } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom';

interface Props extends RouteProps{
    component?: React.ComponentType<any>
}

const PrivateRoute = ({ component, render : Render , ...rest }: Props ) => {
    const [nominate, setNominate] = useState(document.cookie?.split(';')?.filter(x => x.includes('nominate'))[0]?.split('=')[1]);
    const [email, setEmail] = useState(document.cookie?.split(';')?.filter(x => x.includes('email'))[0]?.split('=')[1]);
    useEffect(()=>{
        setNominate(document.cookie?.split(';')?.filter(x => x.includes('nominate'))[0]?.split('=')[1]);
        setEmail(document.cookie?.split(';')?.filter(x => x.includes('email'))[0]?.split('=')[1]);
    });

    const isLoggedIn = () =>{
        return (nominate && nominate!=="") || (email && email!=="")
    }

    return (
        <Route
            {...rest}
            render={(props) => {
                if(isLoggedIn()){
                    return Render ? Render(props)
                    : component ? <Route {...props} component={component}/>: null
                }else{
                    return <Redirect to="/login" />
                }
            }}
        />
      );
}

export default PrivateRoute