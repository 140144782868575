import React from 'react';
import {HelpCenterWrapper, HelpCenterHeader,
    HelpCenterContent} from 'components/Footer/skins';
import closeIcon from 'assets/svg/footer-close.svg';
import phoneSVG from 'assets/svg/footerSvg/phone-footer.svg';
import phone1SVG from 'assets/svg/helpCenter_phone.svg';
import chatSVG from 'assets/svg/helpCenter_chat.svg';
import faqSVG from 'assets/svg/helpCenter_faq.svg';
import emailSVG from 'assets/svg/helpCenter_email.svg';

export default class HelpCenter extends React.Component {
    
    constructor(props) {
        super(props);
        this.displayHelpCenter = this.displayHelpCenter.bind(this);
        this.state = {
            hidden: false
        };
    }

    displayHelpCenter() {
        const currentState = this.state.hidden;
        this.setState({hidden: !currentState});
    }
    
    render() {
        let helpCenter_button = this.state.hidden ? "helpCenter-button" : "display-none";
        let display_none = this.state.hidden ? "display-none" : "footer-helpCenter";
        let footer_helpCenter = this.state.hidden ? "helpCenter-button" : "footer-helpCenter";
        return(
            <HelpCenterWrapper className='helpWrapper'>
                <button type='button'
                    className={helpCenter_button}
                    onClick={this.displayHelpCenter}
                >
                    <h4>HELP CENTER</h4>
                </button>
                <div className={display_none}>
                    <HelpCenterHeader className= 'helpHeader'>
                        <h5>
                            HELP CENTER
                        </h5>
                        <button  className={footer_helpCenter} onClick={this.displayHelpCenter}>
                            <img src={closeIcon} alt={""}/>
                        </button>
                    </HelpCenterHeader>
                    <HelpCenterContent className='helpInfo'>
                        <div className='helpCenter-info'>
                            How can we help? Call, chat with a rep, get answers to FAQs or send us an email.
                        </div>
                        <div className='helpCenter-phoneLinks'>
                            <h5>
                                CALL US
                            </h5>
                            <a className="phoneNumber" href="tel:(855) 724-2387">(855) SAG-AFTRA / (855) 724-2387</a>
                            <div className="emergencyNumber">
                                <a href="tel:(844) 723-3773" className="emergencyNumber_link">ON-SET EMERGENCY: (844) 723-3773 </a>
                            </div>
                        </div>
                        <div className='helpCenter-footer'>
                            <a>
                                <img
                                    src={phone1SVG}
                                    alt=''
                                />
                                <span>
                                    Phone
                                </span>
                            </a>
                            <a>
                                <img
                                    src={chatSVG}
                                    alt=''
                                />
                                <span>
                                    Phone
                                </span>
                            </a>
                            <a>
                                <img
                                    src={faqSVG}
                                    alt=''
                                />
                                <span>
                                    Phone
                                </span>
                            </a>
                            <a>
                                <img
                                    src={emailSVG}
                                    alt=''
                                />
                                <span>
                                    Phone
                                </span>
                            </a>
                        </div>
                    </HelpCenterContent>
                </div>
            </HelpCenterWrapper>
        );
    }
}