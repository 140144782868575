import React, { useEffect, useState } from 'react';
import {useLocation, withRouter} from 'react-router-dom';
import { PpHeader, SiteNavigationSection, YellowBar, NavBar, LoginSection } from './skins';
import '../../../src/index.css';
import sagLogo from 'assets/svg/sag-logo.svg';
import { useSelector } from 'react-redux';


const Header = (props) => {
    const location = useLocation();
    let [isUser, setIsUser] = useState('');
    const [delegatedID, setdelegatedID] = useState(document.cookie?.split(';')?.filter(x => x.includes('userId'))[0]?.split('=')[1]);
    const [showLoginDD, setShowLoginDD] = useState(false);
    useEffect(() => {
        let a = document.cookie?.split(';')?.filter(x => x.includes('userId'))[0]?.split('=')[1];
        setdelegatedID(a);
    }, [isUser])
    

    setTimeout(()=> {
        setIsUser(document.cookie?.split(';')?.filter(x => x.includes('fullName'))[0]?.split('=')[1]);
    }, 2000);

    function handleNavigation(e, val) {
        props.history.push(val);
    }

    const showForDelegatedUser = () => {
        return delegatedID && delegatedID !=="undefined" && delegatedID!=="";
    }

    const handleLogout = () => {
        document.cookie.split(";").forEach(function(c) { if(!c.includes('nominate')){document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");}});
       window.location.href = process.env.REACT_APP_LOGOUT_REDIRECT
    }

    return (
        <PpHeader className='mainHead'>
            <SiteNavigationSection className='siteNav'>
                <YellowBar></YellowBar>
                <NavBar className='navBar'>
                    <ul>
                    <li className='cursor-pointer'>
                            <img style={{width:"6em"}} src={sagLogo} alt="SAG-AFTRA Logo" onClick={()=>window.location.href = 'https://www.sagaftra.org/'}/>
                        </li>
                        {isUser && (
                            <><li>
                            <button
                                className='remove-button-style cursor-pointer'
                                type='button'
                                onClick={(e)=>handleNavigation(e, '/my-productions')}
                            >
                                My Productions
                            </button>
                        </li>
                        <li>
                            <button
                                className='remove-button-style cursor-pointer'
                                type='button'
                                onClick={(e)=>handleNavigation(e, '/message-center')}
                            >
                                Message Center
                            </button>
                        </li>
                        <li>
                            <button
                                className='remove-button-style cursor-pointer'
                                type='button'
                                onClick={(e)=>handleNavigation(e, '/faqs')}
                            >
                                FAQs
                            </button>
                        </li>
                        {showForDelegatedUser() && <li>
                            <button
                                className='remove-button-style cursor-pointer'
                                type='button'
                                onClick={(e)=>handleNavigation(e, '/my-account')}
                            >
                                My Account
                            </button>
                        </li>
                        }</>
                        )}
                    </ul>
                </NavBar>
            </SiteNavigationSection>
            <LoginSection className='loginSection'>
                <button type="button" onClick={()=>window.location.href = 'https://www.sagaftra.org/'}>
                    SAG-AFTRA MAIN SITE
                </button>
                <hr/>
                <div className='loginDDContainer'>
                {isUser ? (<><button type="button" className='loginBtn' onClick={() => setShowLoginDD(!showLoginDD)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="21" viewBox="0 0 18 21">
    <path fill="#fff" fill-rule="evenodd" d="M2.178 18.806c.356-3.279 3.086-5.84 6.392-5.84 3.305 0 6.035 2.561 6.391 5.84H2.178zM8.57 2.753c2.1 0 3.807 1.744 3.807 3.888 0 2.142-1.708 3.885-3.807 3.885-2.1 0-3.807-1.743-3.807-3.885 0-2.144 1.708-3.888 3.807-3.888zm3.314 8.977a6.166 6.166 0 0 0 2.679-5.09c0-3.373-2.689-6.117-5.993-6.117-3.305 0-5.993 2.744-5.993 6.118a6.168 6.168 0 0 0 2.678 5.09C2.096 13.087 0 16.28 0 19.795c0 .108.006.214.013.318l.03.537H17.095l.03-.528c.006-.108.014-.217.014-.327 0-3.517-2.096-6.71-5.256-8.066z"></path>
</svg>
Hi {isUser}
                </button>
                {showLoginDD && <section> 
                    <button  onClick={(e)=>handleNavigation(e, '/my-account')}>My Info</button>
                    <button onClick={()=>handleLogout()}>Logout</button>
                </section> }</>): (<button type="button" onClick={(e)=>handleNavigation(e, '/login')}>
                
LOGIN</button>)}
                
                </div>
            </LoginSection>
        </PpHeader>
    )
}

export default withRouter(Header);
// export default Header;