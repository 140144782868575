export const STORE_HASH = 'STORE_HASH';
export const STORE_GLOBALS = 'STORE_GLOBALS';

export const documentCategories = [
    {
        value: '1st Reminder Notice Commercials',
        label: '1st Reminder Notice Commercials',
    },
    {
        value: '1st Reminder Notice Theatrical',
        label: '1st Reminder Notice Theatrical',
    },
    {
        value: '1st Reminder Notice Tv',
        label: '1st Reminder Notice Tv',
    },
    {
        value: '2nd Reminder Notice Commercials',
        label: '2nd Reminder Notice Commercials',
    },
    {
        value: '2nd Reminder Notice Theatrical',
        label: '2nd Reminder Notice Theatrical',
    },
    {
        value: '2nd Reminder Notice Tv',
        label: '2nd Reminder Notice Tv',
    },
    {
        value: 'ACTRA MOA',
        label: 'ACTRA MOA',
    },
    {
        value: 'Adherence Letter - GR-1 Memorandum',
        label: 'Adherence Letter - GR-1 Memorandum',
    },
    {
        value: 'Adherence Letter - P&H Adherence',
        label: 'Adherence Letter - P&H Adherence',
    },
    {
        value: 'Background Actor Contract',
        label: 'Background Actor Contract',
    },
    {
        value: 'Branch Letter Commercials',
        label: 'Branch Letter Commercials',
    },
    {
        value: 'Basic Cable Agreement',
        label: 'Basic Cable Agreement',
    },
    {
        value: 'Budget Detail',
        label: 'Budget Detail',
    },
    {
        value: 'Call Sheets',
        label: 'Call Sheets',
    },
    {
        value: 'Casting Data - Performer',
        label: 'Casting Data - Performer',
    },
    {
        value: 'Chain of Title',
        label: 'Chain of Title',
    },
    {
        value: 'Collection/Escrow Agreement',
        label: 'Collection/Escrow Agreement',
    },
    {
        value: 'Employee Waivers',
        label: 'Employee Waivers',
    },
    {
        value: 'Lit / Court Rulings',
        label: 'Lit / Court Rulings',
    },
    {
        value: 'Minor Waivers',
        label: 'Minor Waivers',
    },
    {
        value: 'NLRB Rulings / Decisions',
        label: 'NLRB Rulings / Decisions',
    },
    {
        value: 'PSA Waivers',
        label: 'PSA Waivers',
    },
    {
        value: 'Standing Committee Minutes',
        label: 'Standing Committee Minutes',
    },
    {
        value: 'Turnaround Agreement',
        label: 'Turnaround Agreement',
    },
    {
        value: 'Writer Services Agreement',
        label: 'Writer Services Agreement',
    },
    {
        value: 'Referral to Legal',
        label: 'Referral to Legal',
    },
    {
        value: 'Resolution/Settlement Document',
        label: 'Resolution/Settlement Document',
    },
    {
        value: 'Tolling Agreement',
        label: 'Tolling Agreement',
    },
];

export const documentCategories1 = [
    '1st Reminder Notice Commercials',
    '1st Reminder Notice Theatrical',
    '1st Reminder Notice Tv',
    '2nd Reminder Notice Commercials',
    '2nd Reminder Notice Theatrical',
    '2nd Reminder Notice Tv',
    'ACTRA MOA',
    'Adherence Letter - GR-1 Memorandum',
    'Adherence Letter - P&H Adherence',
    'Background Actor Contract',
    'Branch Letter Commercials',
    'Basic Cable Agreement',
    'Budget Detail',
    'Call Sheets',
    'Casting Data - Performer',
    'Chain of Title',
    'Collection/Escrow Agreement',
    'Employee Waivers',
    'Lit / Court Rulings',
    'Minor Waivers',
    'NLRB Rulings / Decisions',
    'PSA Waivers',
    'Standing Committee Minutes',
    'Turnaround Agreement',
    'Writer Services Agreement',
    'Referral to Legal',
    'Resolution/Settlement Document',
    'Tolling Agreement',
];
export const PASSWORD_HASH_KEY = '$2a$10$CwTycUXWue0Thq9StjUM0u';