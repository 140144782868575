import React from 'react';
import Select, {components} from 'react-select';

const DropdownIndicator = props => {
    // const {blackIcon} =props.selectProps;
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
            <div>
                <div className={`caret-icon`} />
           </div>
        </components.DropdownIndicator>
      )
    );
  };

let DropDownField = (props) => {
    const {styles, blackCaretIcon} = props;
    return (
        <>
            <Select
                {...props}
                components={{DropdownIndicator}}
                styles={styles}
                blackIcon={blackCaretIcon}
            />
        </>
    );
}

export default DropDownField;