import { STORE_HASH, STORE_GLOBALS } from '../constants';

const INITIAL_STATE = {};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case STORE_HASH:
            return {
                ...state,
                hash: action.hash
            }
        case STORE_GLOBALS:
            return {
                ...state,
                globals: action.val
            }
        default: return state;
    }
}

export default reducer;