export const options = [
    {value: 'stage1', label: 'stage1'},
    {value: 'stage2', label: 'stage2'},
    {value: 'stage3', label: 'stage3'},
    {value: 'stage4', label: 'stage4'},
];

export const partners = [{
    value:'P1', label:'SAG-AFTRA Foundation', url: 'https://www.sagaftrafoundation.org/', target:'_blank'
},{
    value:'P2', label: 'SAG-AFTRA Health Plan', url: 'https://www.sagaftraplans.org', target:'_blank'
},{
    value:'P3', label:'AFTRA Retirement Fund', url: 'https://aftraretirement.org/', target: '_blank'
},{
    value:'P4', label: 'SAG-Producers Pension Plan', url: 'http://www.sagaftraplans.org/', target:'_blank'
},{
    value:'P5', label: 'SAG Awards', url: 'http://www.sagawards.org/', target:'_blank'
},{
    value:'P6', label: 'SAGindie', url: 'https://www.sagindie.org/', target:'_blank'
},{
    value:'P7', label: 'The Actors Fund', url: 'http://actorsfund.org/', target:'_blank'
},{
    value:'P8', label: 'SAG-AFTRA Federal Credit Union', url: 'https://www.sagaftrafcu.org/home/home', target:'_blank'
},{
    value:'P9', label: 'Actors Federal Credit Union', url: 'https://actorsfcu.com/sag-aftra', target:'_blank'
},{
    value:'P10', label: 'MPPWF', url: 'https://www.sagaftra.org/mppwf', target:'_blank'
},{
    value:'P11', label: 'Union Plus', url: 'https://www.unionplus.org/', target:'_blank'
}];

export const resultsPerPage = [
    {value: '10', label: '10'},
    {value: '20', label: '20'},
    {value: '50', label: '50'},
    {value: '100', label: '100'},
]