import { setConstantValue } from "typescript";

export const baseStreakUrl = process.env.REACT_APP_BASE_STREAK_URL;
export const baseApiUrl = process.env.REACT_APP_BASE_API_URL;
export const newProductionUrl = process.env.REACT_APP_NEW_PRODUCTION_URL;
export const referrer = process.env.REACT_APP_REFERRER;
export const passwordResetRedirectUrl = process.env.REACT_APP_passwordResetRedirectUrl;
export const API_SECRET_KEY  = process.env.REACT_APP_X_API_KEY;

export const getProductions = `${baseStreakUrl}/sigapp/prod/detail/userids`;
export const sso = `${baseApiUrl}/authenticate/user/sso`;
export const uploadFiles = `${baseApiUrl}/upload-files`;
export const getEmails = `${baseStreakUrl}/streakapp/threads/email`;
export const getEmailThread = `${baseStreakUrl}/streakapp/messages/threadid`;
export const getProdDetailsByProdID = `${baseStreakUrl}/sigapp/prod/detail`;
export const getDocumentInfoByProdId = `${baseStreakUrl}/sigapp/documentinfo`;
export const updateDocumentRead = `${baseStreakUrl}/sigapp/document/note/read`;
export const userInvite = `${baseStreakUrl}/sigapp/sendemail/userinvite`;
export const documentTypes = `${baseStreakUrl}/sigapp/doctypes`;
export const uploadDocuments = `${baseStreakUrl}/sigapp/file/upload/documents`;
export const userManagement = `${baseStreakUrl}/sigapp/users/producer/adminid`;
export const downloadDocument = `${baseStreakUrl}/sigapp/download`;
export const getRoles = `${baseStreakUrl}/sigapp/roles`;
export const getProductionIds = `${baseStreakUrl}/sigapp/prodids`;
export const getProductions1 = `${baseStreakUrl}/sigapp/prod/details`;
export const updateDelegatedUser = `${baseStreakUrl}/sigapp/delegateduser`;
export const updateDelegatedUserName = `${baseStreakUrl}/sigapp/delegateduser/username/email`;
export const login = `${baseStreakUrl}/sigapp/authenticate/delegateduser`;
export const getDelegatedId = `${baseStreakUrl}/sigapp/userid/email`;
export const resetPasswordMail = `${baseStreakUrl}/sigapp/sendemail/password/reset`;
export const forgotPassowrd = `${baseStreakUrl}/sigapp/delegateduser/password`;
export const getDetailsByDelegatedUserId =  `${baseStreakUrl}/sigapp/details/delegateduser`;
export const updateDelegatedUserPolicyFlag =  `${baseStreakUrl}/sigapp/delegateduser/policyflag`;