import styled, { css, createGlobalStyle } from 'styled-components';

const DisplayFlex = css`
    display: flex;
`;

const GlobalStyle = createGlobalStyle`
    body {
        --gray: #f5f5f5;
        --osloGray: #8a8b8c;
        --yellow: #fed100;
        --red: #c8062a;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .display-none {
        display: none;
    }

    .remove-button-style {
        border: none;
        background-color: white;
        padding: 0;
    }

    .margin-hrtl-auto {
        margin-left: auto;
        margin-right: auto;
    }

    .required-field {
        color: var(--red);
    }

    .back-button {
        ${DisplayFlex};
        align-items: center;
        cursor: pointer;

        img {
            width: 2.5em;
        }
    }
    input::-webkit-calendar-picker-indicator {
        cursor: pointer;
      }  
      input,select {
        background: #fff;
      }
      .select {
        position: relative;
        height: 46px;
        background: white;
        border: 1px solid #000;
        
        &:after {
          content: '';
          position: absolute;
          top: 0;
          width: 0;
          height: 0;
          right: 10px;
          bottom: 0;
          margin: auto;
          border-style: solid;
          border-width: 5px 5px 0 5px;
          border-color: #000 transparent transparent transparent;
          pointer-events: none;
        }
        
        &:before {
          width: 30px;
          position: absolute;
          top: 1px;
          right: 1px;
          bottom: 1px;
          background: #fff;
          content: '';
          pointer-events: none;
        }
        
        
        
        select {
          border: none;
          box-shadow: none;
          border-radius: 0;
          background: transparent;
          height: 100%;
          width: 100%;
          cursor: pointer;
          outline: none;
          padding-right: 35px;
          padding-left: 15px;
      
          // Disable default styling on ff
          -moz-appearance: none;
          
          // Disable ugly ass outline on firefox 
          &:-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 #000;
          } 
          
          // Disable default styling on webkit browsers 
          -webkit-appearance: none;
          
          // Disable default arrow on IE 11+
          &::-ms-expand {
            display: none;
          }
          
          &:focus {  
            border-color: #63ffb4;    
          }
        }
      }

      .text-left {
        text-align: left;
      }

      .text-right {
        text-align: right;
      }

      .margin-bottom-15 {
        margin-bottom: 15px;
      }

      .margin-vrtl-15 {
        margin: 15px 0;
      }
    `;

const GlobalContainerDiv = styled.div`
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;
    overflow-x: hidden;
`;

export { GlobalStyle, GlobalContainerDiv, DisplayFlex };
<script type="text/javascript" src="https://sc23269568hms1.cobrowse.oraclecloud.com/launcher.js"></script>
