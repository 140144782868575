import styled from 'styled-components';
import { DisplayFlex } from 'skins';

const Footer = styled.footer`
    background-color: #eee;
    padding-top: 2em;
    overflow-y: hidden;
    margin-top: 2em;
    
    h3 {
        margin-left: 20%;
        font-weight: 550;
        font-size: 1.5em;
    }
`;

const FooterMainSection = styled.div`
    margin-left: 20%;
    margin-top: 4em;
    display: flex;
`;

const AddressSection = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 1 30%;
    line-height: 1.8em;

    .phone-contact {
        margin-top: 2em;
    }

    .email-contact {
        margin-top: 1em;
    }

    .phone-contact,
    .email-contact {
        line-height: initial;
        display: flex;
        align-items: center;
        cursor: pointer;

        svg, img {
            margin-right: 0.5em;
        }

        a {
            text-decoration: none;
            color: black;
            border-bottom: 2px solid var(--yellow);
        }
    }
`;

const LinksSection = styled.div`
    flex: 0 1 30%;
    ul {
        list-style-type: none;
        margin-top: 0;
        display: flex;
        flex-direction: column;
        height: 15em;
        justify-content: space-between;
    }

    a {	
        text-decoration: none;	
        color: black;	
        &:hover {	
            cursor: pointer;	
        }	
    }

    .copyright {
        margin-top: 7em;
        margin-left: 2.5em;
        color: gray;
    }
`;

const PartnersDropdownSection = styled.div`
    flex: 0 1 20%;
    .caret-icon {
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid #fff;
        cursor: pointer;
    }
`;

const SocialMediaLinksSection = styled.div`
    .follow-us {
        margin-top: 10em;
        color: grey;
    }
    .social-links {
        display: flex;
        justify-content: space-between;
        width: 12em;
        margin-left: 2em;
        margin-top: 1em;
        cursor: pointer;
    }
`;

const SagLogo = styled.div`
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    transform: translateY(35%);
`;

/* Help Center Styles */

const HelpCenterWrapper = styled.div`
    font-family: 'Avenir Next W01';
    overflow-anchor: none;
    position: fixed;
    bottom: 0;
    right: 0;
    display: block;
    
    .helpCenter-button {
        height: 65px;
        background-color: var(--red);
        color: white;
        height: 4em;
        width: 10em;
        border: 1px solid var(--red);

        h4 {
            font-family: 'Avenir Next W01';
            text-align: center;
            color: #fff;
            font-size: 11px;
            font-weight: 600;
            line-height: 1.09;
            letter-spacing: 1.4px;
            margin-bottom: 0;
            text-transform: uppercase;
            margin-top: 0;
        }

        h4::after {
            content: '';
            position: relative;
            top: -9px;
            width: 0;
            height: 0;
            margin-left: 9px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #fff;
        }
    }

    .helpCenter-button:hover {
        cursor: pointer;
    }

    .footer-helpCenter {
        width: 23em;
    }
`;

const HelpCenterHeader = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
    color: white;
    background-color: var(--red);

    h5 {
        padding-left: 1em;
    }

    button {
        border: none;
        background-color: var(--red);
        margin-left: 3em;
    }

    button:hover {
        cursor: pointer;
    }

    button:focus {
        outline: none;
    }

    button>img {
        height: 1em;
    }
`;

const HelpCenterContent = styled.div`
    background-color: white;

    .helpCenter-info {
        padding: 2em 1em;
    }

    .helpCenter-phoneLinks {
        padding: 2em 1em;
        height: 12em;

        h5 {
            margin-bottom: 5px;
            margin-top:0;
        }

        .phoneNumber {
            border-bottom: 2px solid var(--yellow);
            color:black;
            padding-bottom: 2px;
            text-decoration: none;
        }

        .emergencyNumber {
            text-decoration: none;
            margin-top: 7em;

            a {
                color: var(--red);
            }
        }
    }

    .helpCenter-footer {
        ${DisplayFlex};
        justify-content:space-around;
        background-color: var(--gray);
        height: 4em;
        padding-top: 1em;

        a {
            ${DisplayFlex};
            flex-direction:column;
            
            img {
                height: 2em;
            }
        }
    }
`;

export {Footer, FooterMainSection, AddressSection,
    LinksSection, PartnersDropdownSection, SagLogo,
    SocialMediaLinksSection, HelpCenterWrapper,
    HelpCenterHeader, HelpCenterContent};
