import {sso,API_SECRET_KEY } from 'strings';

export const Sso = () => {
    let a = document.cookie.split(';').filter(x => x.includes('nominate'));
    let b = {hash: a[0]?.split('=')[1]??''};
    console.log(a,b);
    return (fetch(sso, {
        method: 'post',
        body: JSON.stringify(b)
    })
    .then(response => response.json())
    .then(data => {
        console.log(data);
        if (data.payload && data.http_code===200) {
            document.cookie = `fullName=${data?.payload?.fullName}; Samesite=lax; Path=/; Secure=true`;
            document.cookie = `producerId=${data?.payload?.producerId}; Samesite=lax; Path=/; Secure=true`;
            document.cookie = `userId=${data?.payload?.userID}; Samesite=lax; Path=/; Secure=true`;
            document.cookie = `email=${data?.payload?.email}; Samesite=lax; Path=/; Secure=true`;
            document.cookie = `userName=${data?.payload?.username}; Samesite=lax; Path=/; Secure=true`;
        }
        return data;
    })
    .catch(err => {
        console.log(err);
    }));
}