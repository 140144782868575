import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from 'reportWebVitals';
import 'index.css';
import { GlobalStyle, GlobalContainerDiv } from 'skins';
import Header from 'components/Header';
import FooterComponent from 'components/Footer';
import store from 'store/store';
import RouteGuard from 'routeGuard';


ReactDOM.render(
  // <React.StrictMode>
    <Provider store={store}>
      <Router>
          <GlobalContainerDiv>
            <GlobalStyle/>
            <Header/>
            <Suspense fallback={<div>Loading...</div>}>
                <RouteGuard/>
            </Suspense>
            <FooterComponent/>
          </GlobalContainerDiv>
      </Router>
    </Provider>
  // </React.StrictMode>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
